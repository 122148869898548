@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i|Open+Sans:300,400,500,700,800');

@slider-font: 'Open Sans', sans-serif;
@body-font: 'Open Sans', sans-serif;
@menu-font: @body-font;
@heading-font:  'Open Sans', sans-serif;
@heading-font-alt: @heading-font;


@section-bg-light: #F8F9FA;
@primary-color: #ad6844; //#FE3C47; #98cb2b
@secondary-color: #444357; //#f6cf6a
 
@top-bar-bg: #5c5c5c;
@top-bar-text: #FFFFFF;
@light-text-color: #FFFFFF;
@dark-text-color: #111111;
@default-font-color: #646464; //body font
@text-color: @default-font-color;
@pure-white-bg: #FFFFFF;
@pure-black-bg: #111111;

@main-top-header-2: #FFFFFF;
@nav-bar-2-bg: #FAFAFA;

@primary-bg-color: #FFFFFF; 
@second-bg-color: #EDF0F2;
@border-color: #EEE;
@dark-bg: #2C2C2C;
@light-bg: #FAFAFA;
@blog-bg-color: #F9FAFB;


@dark-color: #000000;

@gray-color: #EEEEEE;
@light-color: #F8F8F8;

@top-menu-background: #FFFFFF;
@main-menu-background: #FFFFFF;
@button-background: #399FD2;
@blockquote-background: #f8f8f8;
@separator-border: #808080;

@background-color: #FFFFFF;
@sidebar-bg: #FFFFFF;
@sub-text-color: #555555;

@light-border-color: #CCCCCC;
@dark-border-color: #111111;
@primary-btn-bg: @primary-color;
@secondary-btn-bg: @secondary-color;

@footer-bg: #1a1a1a;
@footer-sidebar-bg: #333333;
@footer-text-color: #C2C2C2;
@footer-sidebar-border: #444444;

@top-menu-bg: @footer-sidebar-bg;
@main-menu-bg: @footer-sidebar-bg;


@heading-font-color: #555555;
@header-background-color: rgba(256,256,256,1);
@anchor-color: #272727;